import React from "react";
import {CodeIcon} from "@heroicons/react/solid";
import {PhoneIcon} from "@heroicons/react/solid";
import {InboxIcon} from "@heroicons/react/solid";

export default function Contact() {
    return (
        <section id="contact" className="relative">
            <div className="flex flex-col pt-10 w-full mb-20 text-center">
                <CodeIcon className="mx-auto w-10 mb-4" />
                <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                    Contact
                </h1>
                <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                    In case you want to get in touch with me.
                </p>
            </div>

            <div className="container px-5 pb-10 mx-auto flex sm:flex-nowrap flex-wrap">
                <a className="container mx-5 px-5 py-10 mx-auto flex-col sm:flex-nowrap flex-wrap border border-slate-100/50 rounded-md text-center hover:bg-neutral-700 hover:text-white" href="tel:+31645483219">
                    <PhoneIcon className="mx-auto inline-block w-10 mb-4" />
                    <h2 className="sm:text-2xl text-1xl font-medium title-font mb-4 text-white">
                        Phone
                    </h2>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base" >
                        +31 6 45 48 32 19
                    </p>
                </a>
                <a className="container mx-5 px-5 py-10 mx-auto flex-col sm:flex-nowrap flex-wrap border border-slate-100/50 rounded-md text-center hover:bg-neutral-700 hover:text-white"  href="mailto:work@yzhang.nl">
                    <InboxIcon className="mx-auto inline-block w-10 mb-4" />
                    <h2 className="sm:text-2xl text-1xl font-medium title-font mb-4 text-white">
                        Email
                    </h2>
                    <div className="lg:w-2/3 mx-auto leading-relaxed text-base">
                        work@yzhang.nl
                    </div>
                </a>
                <a className="container mx-5 px-5 py-10 mx-auto flex-col sm:flex-nowrap flex-wrap border border-slate-100/50 rounded-md text-center hover:bg-neutral-700 hover:text-white"  href="https://www.linkedin.com/in/yingzhangnl/">
                    <div className="mx-auto block w-10 mb-4">
                        <img
                            className="object-cover object-center"
                            alt="hero"
                            src="./linkedin.svg"
                        />
                    </div>
                    <h2 className="sm:text-2xl text-1xl font-medium title-font mb-4 text-white">
                        LinkedIn
                    </h2>
                    <div className="lg:w-2/3 mx-auto leading-relaxed text-base">
                        linkedin.com/in/yingzhangnl/
                    </div>
                </a>
            </div>
        </section>
    );
}