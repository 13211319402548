export const projects = [
    {
        title: "Chess Vision",
        subtitle: "Native Android App (Java)",
        description:
            "An android application for monitoring chess games. With as goal to provide a less expensive replacement for a so-called live-board at a tournament, which stores the players' moves and games for future analysis.",
        image: "./chess.jpg",
        link: "/",
    },
    {
        title: "BedBasedEcho",
        subtitle: "C++ and Python",
        description:
            "Developing software of a prototype for an automatically guided ultrasound probe for cardiac sonography using robotics and machine learning. Leading to a quick and inexpensive way to acquire ultrasound images without the need of a specialist operating the device.",
        image: "./bed.png",
        link: "/",
    },
    {
        title: "POMD-TER",
        subtitle: "Furhat SDK (Java) and Python",
        description:
            "Task-oriented dialogue system for computer repair center. A data-driven human-like agent (using several machine learning techniques ranging from deep reinforcement learning for conversation flow to deep neural nets for speech synthesis) converses over video call with a user.",
        image: "./robot.jpg",
        link: "/",
    },
    {
        title: "A Hole New Perspective",
        subtitle: "Unity (C#)",
        description:
            "A video game meant to train an individual's perspective taking ability. Players are challenged to rotate an object such that it fits through the hole of an approaching wall. To nudge players to use their perspective skill, hole in the wall itself is not always aligned with the object.",
        image: "./ahole.png",
        link: "/",
    },
    {
        title: "Cryptanalytic Model Extraction",
        subtitle: "Python (PyTorch)",
        description:
            "A reimplementation of Cryptanalytic Extraction of Neural Network Models by Nicholas Carlini, Matthew Jagielski, Ilya Mironov, using PyTorch. It steals model parameters by repeatedly running inference by exploiting piecewise linear nature of ReLU activations.",
        image: "./hacking.jpg",
        link: "/",
    },
    {
        title: "Kibbeling",
        subtitle: "D3.js (JavaScript)",
        description:
            "An interactive visualization of the Food.com recipes. Allows the user to select a combination of ingredients and find out which cuisines often use those ingredients in their recipes. It uses chord diagrams, bar charts and pie charts to display relations and relative frequency of ingredients.",
        image: "./kibbeling.png",
        link: "/",
    },
    {
        title: "SuperFantasyLEC analyser",
        subtitle: "Python",
        description:
            "Extract data from the Super Fantasy LEC API and visualise common statistics to guide player and item decisions for the next week. It can find data either by team for selecting the coach, by player for selecting the player or by role to guide item selection.",
        image: "./fantasy.jpeg",
        link: "/",
    },
    {
        title: "Efficiency of Energy Communities",
        subtitle: "MSc Research",
        description:
            "Analysis on the efficiency of peer-to-peer trading in energy communities by simulating efficient control and trade using heuristic-based algorithms and automated negotiation. Also providing insights into trading dynamics using various data visualisation techniques.",
        image: "./wind.jpg",
        link: "/",
    },
];