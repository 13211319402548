import { CodeIcon } from "@heroicons/react/solid";
import { ChevronDoubleDownIcon} from "@heroicons/react/solid";
import { ChevronDoubleUpIcon} from "@heroicons/react/solid";
import {projects} from "../data";
import {useState} from "react";

export default function Projects() {
    const [showAll, setShowAll] = useState(false);

    let numberOfProjects = 4;
    let showButton;

    if(showAll) {
        numberOfProjects = projects.length;
        showButton =
            <button type="button"
                    onClick={() => setShowAll(false)}
                    className="lg:w-2/3 px-5 py-10 mx-auto leading-relaxed text-base hover:text-white">
                <ChevronDoubleUpIcon className="mx-auto inline-block w-10 mb-4" />
            </button>
    } else {
        showButton =
            <button type="button"
                    onClick={() => setShowAll(true)}
                    className="lg:w-2/3 px-5 py-10 mx-auto leading-relaxed text-base hover:text-white">
                <ChevronDoubleDownIcon className="mx-auto inline-block w-10 mb-4" />
            </button>
    }

    return (
        <section id="projects" className="text-gray-400 bg-neutral-900 body-font">
            <div className="container px-5 py-10 mx-auto text-center lg:px-40">
                <div className="flex flex-col w-full mb-20">
                    <CodeIcon className="mx-auto inline-block w-10 mb-4" />
                    <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                        Projects
                    </h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                        These are the notable projects I have taken part in during my studies, internships and free time.
                    </p>
                </div>
                <div className="flex flex-wrap -m-4">
                    {projects.slice(0, numberOfProjects).map((project) => (
                        <a
                            href={project.link}
                            key={project.image}
                            className="sm:w-1/2 w-100 p-4">
                            <div className="flex relative">
                                <img
                                    alt="gallery"
                                    className="absolute inset-0 w-full h-full object-cover object-center"
                                    src={project.image}
                                />
                                <div className="px-8 py-10 relative z-10 w-full border-4 border-neutral-800 bg-neutral-900 opacity-0 hover:opacity-100">
                                    <h2 className="tracking-widest text-sm title-font font-medium text-green-400 mb-1">
                                        {project.subtitle}
                                    </h2>
                                    <h1 className="title-font text-lg font-medium text-white mb-3">
                                        {project.title}
                                    </h1>
                                    <p className="leading-relaxed">{project.description}</p>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
                <div className="flex space-x-2 justify-center ">
                    {showButton}
                </div>
            </div>
        </section>
    );
}